import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}


/**
 * Returns given date object in a human readable format.
 * @param date - date object to format.
 */
export function formattedDateTime(date: Date) {
    let formattedDateString = date.toLocaleDateString(
        'en-in',
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'utc'
        }
    );

    let formattedTimeString = date.toLocaleTimeString(
        'en-in',
        {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }
    )

    return formattedDateString + " " + formattedTimeString
}

/**
 * Returns current theme mode "light" or "dark".
 * Additionaly if no value is available, sets it to system by default and returns "light" or "dark"
 * based on preference.
 */
export function getCurrentTheme(): string {
    let theme = localStorage.getItem("aicaller_theme");
    if (theme === null) {
        // Set to system theme.
        localStorage.setItem("aicaller_theme", "system");
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";
    } else if (theme === "system") {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";

    } else {
        return theme;
    }
}

export function changeTheme(value: string): void {
    switch (value) {
        case "dark":
            localStorage.setItem("aicaller_theme", "dark");
            document.getElementsByTagName("html")[0].classList.remove("light");
            document.getElementsByTagName("html")[0].classList.add("dark");
            break;

        case "light":
            localStorage.setItem("aicaller_theme", "light");
            document.getElementsByTagName("html")[0].classList.remove("dark");
            document.getElementsByTagName("html")[0].classList.add("light");
            break;

        case "system":
            localStorage.setItem("aicaller_theme", "system");
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.getElementsByTagName("html")[0].classList.remove("light");
                document.getElementsByTagName("html")[0].classList.add("dark");

            } else {
                document.getElementsByTagName("html")[0].classList.remove("dark");
                document.getElementsByTagName("html")[0].classList.add("light");

            }
    }
}