import {useEffect, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {buttonVariants} from "@components/ui/button";
import {Pencil, Plus} from "lucide-react";
import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import DataTable from "@components/ui/datatable";
import {Link} from "react-router-dom";
import {urls} from "@routes";
import {Badge} from "@components/ui/badge";

interface PageData {
	status_code: number
	status_text: string
	subscription_plans: Array<SubscriptionPlan>
}

interface SubscriptionPlan {
	name: string
	backend_uid: string
	product_id: string
	variant_id: string
	renewal_type: string
	position: number
	usage_seconds: number
	max_local_numbers: number
	max_tollfree_numbers: number
	price: string
	active: boolean
}

export default function AdminSubscriptionPlans() {
	const [pageData, setPageData] = useState<PageData>();

	// Table columns
	const columns: ColumnDef<SubscriptionPlan>[] = [
		{
			accessorKey: "name",
			header: "Plan Name",
		},
		{
			accessorKey: "product_id",
			header: "Product ID",
		},
		{
			accessorKey: "variant_id",
			header: "Variant ID",
		},
		{
			accessorKey: "renewal_type",
			header: "Renewal Type",
			enableGlobalFilter: false,
		},
		{
			accessorKey: "usage_seconds",
			header: "Usage Limit",
			accessorFn: originalRow => usageTimeFormatter(originalRow.usage_seconds),
			enableGlobalFilter: false
		},
		{
			accessorKey: "max_tollfree_numbers",
			header: "Toll Free Numbers",
			cell: props => {
				return <p className="text-center">{props.row.original.max_tollfree_numbers}</p>
			}
		},
		{
			accessorKey: "max_local_numbers",
			header: "Local Numbers",
			cell: props => {
				return <p className="text-center">{props.row.original.max_local_numbers}</p>
			}
		},
		{
			accessorKey: "price",
			header: "Price (USD)",
			accessorFn: originalRow => "$" + parseFloat(originalRow.price).toString(),
			enableGlobalFilter: false
		},
		{
			header: "Active",
			cell: props => {
				if (props.row.original.active) {
					return <Badge variant="default">Active</Badge>
				} else {
					return <Badge variant="destructive">Hidden</Badge>
				}
			},
			enableGlobalFilter: false
		},
		{
			header: "Edit Plan",
			cell: props => {
				return (
					<Link to={urls["adminEditPlan"].replace(":planUID", props.row.original.backend_uid)}
						  className={buttonVariants({variant: "default", size: "sm"})}>
						<Pencil className="w-4 h-4 mr-2"/>Edit
					</Link>
				)
			},
			enableGlobalFilter: false
		},
	]

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["adminSubscriptionPlans"],
		queryFn: () => authenticateAndFetchData("/api/admin/get-all-products/"),
		gcTime: 0,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	function usageTimeFormatter(seconds: number) {
		let minutes = Math.floor(seconds / 60);
		seconds = seconds % 60;

		let minute_string = minutes < 10 ? `0${minutes} min` : `${minutes} min`;
		let seconds_string = seconds < 10 ? `0${seconds} sec` : `${seconds} sec`;
		return `${minute_string} ${seconds_string}`
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center">
				<div className="mb-10 flex flex-col items-center">
					<h1 className="text-4xl font-extrabold text-center mb-2">All Products</h1>
					<p className="text-center">
						Any changes made on admin side won't affect the actual products on LemonSqueezy.<br/>
						Please make sure to keep both in sync.
					</p>
				</div>
				<DataTable columns={columns} data={pageData.subscription_plans}/>
				<Link to={urls["adminAddPlan"]}
					  className={buttonVariants({variant: "default", className: "mt-8"})}>
					<Plus className="w-5 h-5 mr-2"/>Add Subscription Plan
				</Link>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}
