import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button} from "@components/ui/button";
import {Checkbox} from "@components/ui/checkbox";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {SaveIcon} from "lucide-react";
import {urls} from "@routes";
import {Textarea} from "@components/ui/textarea";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";

interface PageData {
	status_code: number
	status_text: string

	name: string
	description: string
	feature_list: string
	product_id: string | null
	variant_id: string | null
	renewal_type: string
	price: string
	display_order: number
	quota_seconds: number
	max_local_numbers: number
	max_toll_free_numbers: number
	popular: boolean
	plan_active: boolean
}

export default function AdminEditPlan() {
	const {planUID} = useParams();
	const navigate = useNavigate();

	const [pageData, setPageData] = useState<PageData>();

	const [displayName, setDisplayName] = useState("");
	const [description, setDescription] = useState("");
	const [features, setFeatures] = useState<string>("");
	const [productID, setProductID] = useState<string>("");
	const [variantID, setVariantID] = useState("");
	const [renewalType, setRenewalType] = useState("");
	const [price, setPrice] = useState("0.00");
	const [displayOrder, setDisplayOrder] = useState<number>(0);
	const [usageQuota, setUsageQuota] = useState<number>(0);
	const [maxLocalNumbers, setMaxLocalNumbers] = useState<number>(0);
	const [maxTollFreeNumbers, setMaxTollFreeNumbers] = useState<number>(0);
	const [popular, setPopular] = useState<boolean>(false);
	const [planActive, setPlanActive] = useState<boolean>(false);

	const [errorText, setErrorText] = useState("");

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminEditPage"],
		queryFn: () => authenticateAndFetchData("/api/admin/get-plan-details?plan_backend_uid=" + planUID),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData;
			setPageData(data);

			setDisplayName(data.name);
			setDescription(data.description);
			setFeatures(data.feature_list);
			setProductID(data.product_id || "");
			setVariantID(data.variant_id || "");
			setRenewalType(data.renewal_type);
			setPrice(data.price);
			setDisplayOrder(data.display_order);
			setUsageQuota(data.quota_seconds);
			setMaxLocalNumbers(data.max_local_numbers);
			setMaxTollFreeNumbers(data.max_toll_free_numbers);
			setPopular(data.popular);
			setPlanActive(data.plan_active);
		}
	}, [pageDataQuery.data]);

	// Mutation for saving subscription plan changes.
	const saveChangesMutation = useMutation({
		mutationKey: ["saveSubscriptionPlanChanges"],
		mutationFn: () => authenticateAndPostData("/api/admin/save-subscription-plan-changes/", {
			"plan_backend_uid": planUID,
			"display_name": displayName.trim(),
			"description": description.trim(),
			"features": features.trim(),
			"product_id": productID.trim() || null,
			"variant_id": variantID.trim() || null,
			"renewal_type": renewalType,
			"display_order": displayOrder,
			"usage_quota": usageQuota,
			"max_local_numbers": maxLocalNumbers,
			"max_toll_free_numbers": maxTollFreeNumbers,
			"price": price,
			"popular_plan": popular,
			"plan_active": planActive,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// send user back.
			navigate(urls["adminSubscriptionPlans"])
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	});

	function saveChanges() {
		if (displayName && renewalType && (displayOrder >= 0) && (usageQuota > 0) &&
			(maxTollFreeNumbers >= 0) && (maxLocalNumbers >= 0) && price) {
			saveChangesMutation.mutate();
		} else {
			setErrorText("Please fill in all the details.")
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col items-center">
				<h1 className="text-4xl font-helvetica-neue-bold">Edit Plan - {planUID}</h1>
				<div className="w-4/12 flex flex-col mt-6 p-6 border rounded-2xl">
					{/* Product Display Name) */}
					<div className="w-full flex flex-col">
						<Label htmlFor="display-name" className="font-bold">
							Plan Name:
						</Label>
						<Input type="text"
							   id="display-name"
							   className="mt-2 border"
							   value={displayName}
							   onChange={e => setDisplayName(e.target.value)}
							   placeholder="Basic"/>
					</div>
					{/* Display Order */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="display-order" className="font-bold">
							Display Order:
						</Label>
						<Input type="number"
							   id="display-order"
							   className="mt-2 border"
							   value={displayOrder}
							   onChange={e => {
								   let value = parseInt(e.target.value);
								   if (isNaN(value)) {
									   setDisplayOrder(0);
								   } else {
									   setDisplayOrder(value);
								   }
							   }}/>
					</div>
					{/* Description */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="description-text" className="font-bold">
							Description:
						</Label>
						<Input type="text"
							   id="description-text"
							   className="mt-2 border"
							   value={description}
							   onChange={e => setDescription(e.target.value)}
							   placeholder="Add a short product description..."/>
					</div>
					{/* Feature Text */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="feature-text" className="font-bold">
							Feature List (one on each line):
						</Label>
						<Textarea id="feature-text"
								  className="mt-2 border"
								  value={features}
								  rows={6}
								  onChange={e => setFeatures(e.target.value)}
								  placeholder="Add each feature text on a new line."></Textarea>
					</div>
					{/* Product ID */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="product-id" className="font-bold">
							Product ID:
						</Label>
						<Input type="text"
							   id="product-id"
							   className="mt-2 border"
							   value={productID}
							   onChange={e => setProductID(e.target.value)}
							   placeholder="Products > Row > ... > Copy ID"/>
					</div>
					{/* Variant ID */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="product-id" className="font-bold">
							Variant ID:
						</Label>
						<Input type="text"
							   id="product-id"
							   className="mt-2 border"
							   value={variantID}
							   onChange={e => setVariantID(e.target.value)}
							   placeholder="Products > Row > ... > Copy Variant ID"/>
					</div>
					{/* Renewal Type */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="renewal-type-select">
							<span className="text-destructive">*</span>&nbsp;Select Billing Period (Renewal Type):
						</Label>
						<Select onValueChange={setRenewalType} value={renewalType} required>
							<SelectTrigger id="renewal-type-select" className="mt-2 border-muted-foreground/50">
								<SelectValue placeholder="Source"/>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="monthly" key="monthly">Monthly</SelectItem>
								<SelectItem value="annual" key="annual">Annual</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<p className="mt-6 text-sm">Note: For prices, please keep 2 decimal digits at the end.</p>
					{/* Price in USD */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="price-inr-monthly" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Price in USD (ex. 0.00, 49.99, 120.00):
						</Label>
						<Input type="text"
							   id="price-inr-monthly"
							   className="mt-2 border"
							   value={price}
							   onChange={e => setPrice(e.target.value)}
							   placeholder="9.99"/>
					</div>
					{/* Usage Quota */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="usage-quota" className="font-bold">
							<span className="text-destructive">*</span>&nbsp;Usage Limit (in seconds):
						</Label>
						<Input type="number"
							   id="usage-quota"
							   className="mt-2 border"
							   value={usageQuota}
							   onChange={e => {
								   let value = parseInt(e.target.value);
								   if (isNaN(value)) {
									   setUsageQuota(0);
								   } else {
									   setUsageQuota(value);
								   }
							   }}/>
					</div>
					<div className="w-full grid grid-cols-2 gap-4 mt-6">
						{/* Local Numbers Limit */}
						<div className="flex flex-col">
							<Label htmlFor="local-numbers-limit" className="font-bold">
								<span className="text-destructive">*</span>&nbsp;Max Local Numbers:
							</Label>
							<Input type="number"
								   id="local-numbers-limit"
								   className="mt-2 border"
								   value={maxLocalNumbers}
								   onChange={e => {
									   let value = parseInt(e.target.value);
									   if (isNaN(value)) {
										   setMaxLocalNumbers(0);
									   } else {
										   setMaxLocalNumbers(value);
									   }
								   }}/>
						</div>
						{/* Toll Free Numbers Limit */}
						<div className="flex flex-col">
							<Label htmlFor="tollfree-numbers-limit" className="font-bold">
								<span className="text-destructive">*</span>&nbsp;Max Toll Free Numbers:
							</Label>
							<Input type="number"
								   id="tollfree-numbers-limit"
								   className="mt-2 border"
								   value={maxTollFreeNumbers}
								   onChange={e => {
									   let value = parseInt(e.target.value);
									   if (isNaN(value)) {
										   setMaxTollFreeNumbers(0);
									   } else {
										   setMaxTollFreeNumbers(value);
									   }
								   }}/>
						</div>
					</div>
					{/* Popular Plan */}
					<div className="flex items-center space-x-2 mt-6">
						<Checkbox id="popular"
								  className="rounded"
								  defaultChecked={popular}
								  onClick={() => {
									  setPopular(!popular);
								  }}/>
						<label
							htmlFor="popular"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							Mark as "Popular Plan"
						</label>
					</div>
					{/* Activate Plan */}
					<div className="flex items-center space-x-2 mt-6">
						<Checkbox id="active-plan"
								  className="rounded"
								  defaultChecked={planActive}
								  onClick={() => {
									  setPlanActive(!planActive);
								  }}/>
						<label
							htmlFor="active-plan"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							Activate Plan
						</label>
					</div>
					<hr className="my-6"/>
					<Button onClick={saveChanges}>
						<SaveIcon className="w-5 h-5 mr-2"/>Save Changes
					</Button>
				</div>
				{errorText && <p className="text-destructive mt-4">{errorText}</p>}
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>

	}


}
