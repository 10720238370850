import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";
import {Button} from "@components/ui/button";
import {ChevronLeft, Dot, LoaderCircle} from "lucide-react";
import {urls} from "@routes";
import PageLoading from "@components/ui/pageloading";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useQuery} from "@tanstack/react-query";
import {ColumnDef} from "@tanstack/react-table";
import {formattedDateTime} from "@lib/utils";
import DataTable from "@components/ui/datatable";
import {Badge} from "@components/ui/badge";

interface PageData {
	status_code: number
	status_text: string

	user_email: string
	username: string
}

interface ConversationCallResponse {
	status_code: number
	status_text: string

	calls: Array<ConversationCall>
}

interface ConversationCall {
	call_uid: string
	twilio_call_sid: string | null
	call_date_ts: number
	number: string
	callee_name: string
	ai_name: string
	voice_name: string
	call_status: "connecting" | "ongoing" | "ended"
}

interface DelegateCallResponse {
	status_code: number
	status_text: string

	calls: Array<DelegateCall>
}

interface DelegateCall {
	call_uid: string
	twilio_call_sid: string | null
	call_date_ts: number
	number: string
	callee_name: string
	ai_name: string
	voice_name: string
	call_status: "connecting" | "ongoing" | "ended"
}

interface BulkProjectsResponse {
	status_code: number
	status_text: string

	projects: Array<BulkProject>
}

interface BulkProject {
	project_uid: string
	project_name: string
	created_at_ts: number
	call_count: number
	status: "processing" | "completed"
}

export default function AdminUserCallLogs() {
	const {userID} = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab") || "conversation";

	const [
		pageData,
		setPageData
	] = useState<PageData>();
	const [
		currentTab,
		setCurrentTab
	] = useState<string>(tab);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserCallLogsPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-call-logs?user_id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
		}
	}, [pageDataQuery.data]);

	function changeTab(value: string) {
		setCurrentTab(value);
	}

	function goBack() {
		navigate(urls["adminUserDetails"].replace(":userID", userID || ""));
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col">
				{/* Header */}
				<div className="flex flex-row justify-start items-center">
					<Button variant="ghost" className="text-xl" onClick={goBack}>
						<ChevronLeft className="w-6 h-6 mr-2"/>Go Back
					</Button>
					<h1 className="font-helvetica-neue-bold text-xl ml-6">
						Call Logs
						for <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
					</h1>
				</div>
				{/* Main Content */}
				<div className="w-full flex flex-col mt-12 items-center justify-center">
					<div className="w-full flex flex-col items-center justify-center">
						<Tabs value={currentTab} onValueChange={changeTab} className="w-full">
							<TabsList className="grid w-full grid-cols-3">
								<TabsTrigger value="conversation">Conversation Calls</TabsTrigger>
								<TabsTrigger value="delegate">Delegate Task Calls</TabsTrigger>
								<TabsTrigger value="bulk">Bulk Call Projects</TabsTrigger>
							</TabsList>
							<TabsContent value="conversation" className="mt-6">
								<ConversationCallLogs userID={userID}/>
							</TabsContent>
							<TabsContent value="delegate" className="mt-6">
								<DelegateCallLogs userID={userID}/>
							</TabsContent>
							<TabsContent value="bulk" className="mt-6">
								<BulkCallProjects userID={userID}/>
							</TabsContent>
						</Tabs>
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function ConversationCallLogs(props: { userID: string | undefined }) {
	const [
		calls,
		setCalls
	] = useState<ConversationCall[]>([]);

	// Query to fetch conversation call logs.
	const fetchTableData = useQuery({
		queryKey: ["adminFetchConversationCalls"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-conversation-calls?user_id=" + props.userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (fetchTableData.data) {
			setCalls((fetchTableData.data.data as ConversationCallResponse).calls);
		}
	}, [fetchTableData.data]);

	// Set up table columns.
	const columns: ColumnDef<ConversationCall>[] = [
		{
			header: "Call UID",
			accessorKey: "call_uid",
			cell: cellProps => (
				<Link to={urls["adminUserConversationCallLog"].replace(
					":userID", props.userID || "").replace(
					":callUID", cellProps.row.original.call_uid)}
					  className="text-primary underline">{cellProps.row.original.call_uid}
				</Link>
			),
		},
		{
			accessorKey: "twilio_call_sid",
			header: "Twilio Call SID",
		},
		{
			accessorKey: "number",
			header: "Phone Number",
		},
		{
			accessorKey: "callee_name",
			header: "Callee Name",
		},
		{
			accessorKey: "ai_name",
			header: "AI Name",
		},
		{
			accessorKey: "voice_name",
			header: "Voice Model",
		},
		{
			header: "Call Date",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.call_date_ts));
			},
		},
		{
			header: "Status",
			cell: props => {
				if (props.row.original.call_status === "connecting") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff9200"/>&nbsp;Connecting...
						</div>
					)

				} else if (props.row.original.call_status === "ongoing") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#00f900"/>&nbsp;Call Ongoing
						</div>
					)

				} else {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff2600"/>&nbsp;Ended
						</div>
					)
				}
			}
		},
	];

	return (
		<div className="w-full flex flex-col items-center justify-center">
			{fetchTableData.isPending ?
				<LoaderCircle className="w-10 h-10 animate-spin"/> :
				<DataTable columns={columns} data={calls}/>}
		</div>
	)
}

function DelegateCallLogs(props: { userID: string | undefined }) {
	const [
		calls,
		setCalls
	] = useState<DelegateCall[]>([]);

	// Query to fetch conversation call logs.
	const fetchTableData = useQuery({
		queryKey: ["adminFetchDelegateCalls"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-delegate-calls?user_id=" + props.userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (fetchTableData.data) {
			setCalls((fetchTableData.data.data as DelegateCallResponse).calls);
		}
	}, [fetchTableData.data]);

	// Set up table columns.
	const columns: ColumnDef<DelegateCall>[] = [
		{
			header: "Call UID",
			accessorKey: "call_uid",
			cell: cellProps => (
				<Link to={urls["adminUserDelegateCallLog"].replace(
					":userID", props.userID || "").replace(
					":callUID", cellProps.row.original.call_uid)}
					  className="text-primary underline">{cellProps.row.original.call_uid}
				</Link>
			),
		},
		{
			accessorKey: "twilio_call_sid",
			header: "Twilio Call SID",
		},
		{
			accessorKey: "number",
			header: "Phone Number",
		},
		{
			accessorKey: "callee_name",
			header: "Callee Name",
		},
		{
			accessorKey: "ai_name",
			header: "AI Name",
		},
		{
			accessorKey: "voice_name",
			header: "Voice Model",
		},
		{
			header: "Call Date",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.call_date_ts));
			},
		},
		{
			header: "Status",
			cell: props => {
				if (props.row.original.call_status === "connecting") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff9200"/>&nbsp;Connecting...
						</div>
					)

				} else if (props.row.original.call_status === "ongoing") {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#00f900"/>&nbsp;Call Ongoing
						</div>
					)

				} else {
					return (
						<div className="flex flex-row items-center">
							<Dot className="w-8 h-8 mr-2" color="#ff2600"/>&nbsp;Ended
						</div>
					)
				}
			}
		},
	];

	return (
		<div className="w-full flex flex-col items-center justify-center">
			{fetchTableData.isPending ?
				<LoaderCircle className="w-10 h-10 animate-spin"/> :
				<DataTable columns={columns} data={calls}/>}
		</div>
	)
}

function BulkCallProjects(props: { userID: string | undefined }) {
	const [
		calls,
		setCalls
	] = useState<BulkProject[]>([]);

	// Query to fetch conversation call logs.
	const fetchTableData = useQuery({
		queryKey: ["adminFetchBulkProjects"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-bulk-call-projects?user_id=" + props.userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (fetchTableData.data) {
			setCalls((fetchTableData.data.data as BulkProjectsResponse).projects);
		}
	}, [fetchTableData.data]);

	// Set up table columns.
	const columns: ColumnDef<BulkProject>[] = [
		{
			header: "Project UID",
			accessorKey: "project_uid",
			cell: cellProps => (
				<Link to={urls["adminUserBulkCallProjectDetails"].replace(
					":userID", props.userID || "").replace(
					":projectUID", cellProps.row.original.project_uid)}
					  className="text-primary underline">{cellProps.row.original.project_uid}
				</Link>
			),
		},
		{
			header: "Project Name",
			accessorKey: "project_name",
		},
		{
			header: "Created On",
			accessorFn: originalRow => {
				return formattedDateTime(new Date(originalRow.created_at_ts));
			},
		},
		{
			cell: props => {
				if (props.row.original.status === "processing") {
					return (
						<Badge variant="secondary">Processing</Badge>
					)
				} else {
					return (
						<Badge variant="default">Completed</Badge>
					)
				}
			},
			header: "Status",
			enableColumnFilter: false,
		},
	];

	return (
		<div className="w-full flex flex-col items-center justify-center">
			{fetchTableData.isPending ?
				<LoaderCircle className="w-10 h-10 animate-spin"/> :
				<DataTable columns={columns} data={calls}/>}
		</div>
	)
}
