import {useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, retryFn} from "@lib/apis";
import {useEffect, useState} from "react";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {VerifyNumber} from "@components/ui/verifynumber";


interface PageData {
	status_code: number
	status_text: string
	user_email: string
	min_one_verified_number: boolean
	verified_numbers: Array<string>
	current_usage_seconds: number
	remaining_qouta_seconds: number
	verified_numbers_count: number
	local_numbers_count: number
	local_numbers_max: number
	tollfree_numbers_count: number
	tollfree_numbers_max: number
}


export default function Dashboard() {
	const [pageData, setPageData] = useState<PageData>();

	// Fetch the page data
	const pageDataQuery = useQuery({
		queryKey: ["dashboard"],
		queryFn: () => authenticateAndFetchData("/api/dashboard/"),
		refetchOnWindowFocus: false,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			console.log(pageDataQuery.data.data);
			setPageData(pageDataQuery.data.data as PageData);
		}
	}, [pageDataQuery.data]);

	/**
	 * Used by VerifyNumber component to signal end (success) of verification process.
	 * When we get this we will refetch the dashboard page data.
	 */
	function number_verified() {
		pageDataQuery.refetch().then();
	}


	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="flex flex-col w-full justify-center items-center">
				{pageData.min_one_verified_number ?
					<UsageDetails totalUsedSeconds={pageData.current_usage_seconds}
								  totalRemainingSeconds={pageData.remaining_qouta_seconds}
								  verifiedNumbersCount={pageData.verified_numbers.length}
								  localNumbersCount={pageData.local_numbers_count}
								  localNumbersMax={pageData.local_numbers_max}
								  tollfree_numbers_max={pageData.tollfree_numbers_max}
								  tollfreeNumbersCount={pageData.tollfree_numbers_count}/> :
					<VerifyNumber number_verified={number_verified}/>}
			</div>
		)
	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function UsageDetails(props: {
	totalUsedSeconds: number,
	totalRemainingSeconds: number,
	verifiedNumbersCount: number,
	localNumbersCount: number,
	localNumbersMax: number,
	tollfreeNumbersCount: number,
	tollfree_numbers_max: number,
}) {
	let usedMinutes: number = Math.floor(props.totalUsedSeconds / 60)
	let usedSeconds: number = props.totalUsedSeconds % 60
	let remainingMinutes: number = Math.floor(props.totalRemainingSeconds / 60)
	let remainingSeconds: number = props.totalRemainingSeconds % 60

	return (
		<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
			<div className="border rounded-2xl p-6 mr-4">
				<p className="font-bold text-muted-foreground">Total Call Time Used:</p>
				<p className="mt-2 text-2xl">{usedMinutes} min {usedSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6 mr-4">
				<p className="font-bold text-muted-foreground">Total Call Time Remaining:</p>
				<p className="mt-2 text-2xl">{remainingMinutes} min {remainingSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6 mr-4">
				<p className="font-bold text-muted-foreground">Numbers Verified:</p>
				<p className="mt-2 text-2xl">{props.verifiedNumbersCount}</p>
			</div>
			<div className="border rounded-2xl p-6 mr-4">
				<p className="font-bold text-muted-foreground">Local Numbers Purchased:</p>
				<p className="mt-2 text-2xl">{props.localNumbersCount} / {props.localNumbersMax}</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Toll Free Numbers Purchased:</p>
				<p className="mt-2 text-2xl">{props.tollfreeNumbersCount} / {props.tollfree_numbers_max}</p>
			</div>
		</div>
	)
}
