import {Label} from "@components/ui/label";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@components/ui/select";
import {ReactNode, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {Button} from "@components/ui/button";
import {ArrowBigDownDash, ArrowLeft, CircleHelp, Clipboard, Info, PhoneIncoming, Plus, SaveIcon} from "lucide-react";
import {Input} from "@components/ui/input";
import {Textarea} from "@components/ui/textarea";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@components/ui/tooltip";
import {useNavigate, useOutletContext} from "react-router-dom";
import {urls} from "@routes";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger
} from "@components/ui/dialog";
import {BaseOutletContext} from "@pages/Base";
import {Separator} from "@components/ui/separator";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@components/ui/tabs";

interface PageData {
	status_code: number
	status_text: string
	user_email: string
	voice_details: Array<VoiceDetails>
	instruction_examples: Array<InstructionExamples>
	active_integrations: {
		sms: boolean
		whatsapp: boolean
		slack: boolean
		google: boolean
	},
	saved_presets: Array<SavedPreset>
}

interface SavedPreset {
	id: number
	preset_name: string
	call_type: "phone_call" | "meeting_call"
	callee_country_code: string
	callee_number: string
	gender: "M" | "F"
	nationality: string
	voice_id: string
	user_name: string
	ai_name: string
	callee_name: string
	language: string
	call_instructions: string
	post_call_agent: string
	agent_instruction: string
}

interface VoiceDetails {
	source: string
	voice_id: string
	voice_name: string
	gender: "M" | "F"
	personalities: Array<string>
	nationality: string
	sample_audio_url: string | null
}

interface InstructionExamples {
	title: string
	instructions: string
	post_call_instructions: string
}

interface MeetingResponse {
	status_code: number
	status_text: string

	meeting_join_url: string
}


export default function Delegate() {
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [pageData, setPageData] = useState<PageData>();
	const [callPlatform, setCallPlatform] = useState<string>("meeting_call");
	const [
		allNationalities,
		setAllNationalities
	] = useState<Array<string>>([]);
	const [
		filteredVoices,
		setFilteredVoices
	] = useState<Array<{ voiceId: string, voiceName: string }>>([]);
	const [countryCode, setCountryCode] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [gender, setGender] = useState<string>("");
	const [nationality, setNationality] = useState<string>("");
	const [voice, setVoice] = useState<string>("");  // This is voice id
	const [voiceSampleURL, setVoiceSampleURL] = useState<string | null>(null);
	const [aiCallName, setAiCallName] = useState<string>("");
	const [userCallName, setUserCallName] = useState<string>("");
	const [calleeName, setCalleeName] = useState<string>("");
	const [language, setLanguage] = useState<string>("");
	const [callInstructions, setCallInstructions] = useState<string>("");
	const [agent, setAgent] = useState<string>("no_agent");
	const [
		postCallInstructions,
		setPostCallInstructions
	] = useState<string>("");
	// const [
	// 	startCallDisabled,
	// 	setStartCallDisabled
	// ] = useState<boolean>(true);
	const [errorText, setErrorText] = useState<string>("");
	const [callUnderway, setCallUnderway] = useState<boolean>(false);
	const [openSavePreset, setOpenSavePreset] = useState(false);
	const [openLoadPreset, setOpenLoadPreset] = useState(false);
	const [
		roomURL,
		setRoomURL
	] = useState<string | undefined>(undefined);

	const agentTooltipText = "You can choose an agent to take additional actions after the call ends. " +
		"Ex. Sending a summary of the conversation to your WhatsApp."
	const agentInstructionTooltipText = "Selected agent will follow these instructions to generate " +
		"the results. Ex. You can ask it to fetch the time and date that was decided for the meeting."

	const navigate = useNavigate();

	// Fetch page data
	const pageDataQuery = useQuery({
		queryKey: ["delegate"],
		queryFn: () => authenticateAndFetchData("/api/delegate-task/"),
		gcTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData;
			if (data.status_code === 310) {
				navigate(urls["quotaExhausted"], {replace: true});
			} else {
				setPageData(data);
			}
		}
	}, [pageDataQuery.data, navigate]);

	// This will fetch all distinct nationalities from the voice data.
	useEffect(() => {
		if (pageData) {
			let nationalities: Array<string> = []
			pageData.voice_details.forEach(voice => {
				if (!nationalities.includes(voice.nationality)) {
					nationalities.push(voice.nationality);
				}
			});
			setAllNationalities(nationalities);
		}
	}, [pageData]);

	// Filters voices when gender, nationality and call platform values are selected/changed.
	useEffect(() => {
		if (gender && nationality && callPlatform) {
			if (pageData) {
				setVoice("");
				setFilteredVoices(pageData.voice_details.filter(voice => {
					if (callPlatform === "meeting_call") {
						return (voice.gender === gender) && (voice.nationality === nationality) && (voice.source === "cartesia")
					} else {
						return (voice.gender === gender) && (voice.nationality === nationality) && (voice.source !== "cartesia")
					}
				}).map(voice => {
					return {voiceId: voice.voice_id, voiceName: voice.voice_name}
				}));
			}
		}
	}, [countryCode, phoneNumber, gender, nationality, callPlatform, pageData]);

	// API call to start conversation.
	const makeCallMutation = useMutation({
		mutationKey: ["makeCall", countryCode, phoneNumber, voice, userCallName, aiCallName, calleeName, language,
			callInstructions, postCallInstructions, agent],
		mutationFn: () => authenticateAndPostData("/api/delegate-task/", {
			"country_code": countryCode,
			"phone_number": phoneNumber,
			"voice_id": voice,
			"user_call_name": userCallName,
			"ai_call_name": aiCallName,
			"callee_name": calleeName,
			"call_language": language,
			"call_instructions": callInstructions,
			"post_call_agent": agent,
			"agent_instructions": postCallInstructions,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Switch to "ongoing call" stage.
			setCallUnderway(true);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		}
	});

	// API Call to start Meeting conversation call.
	const makeMeetingCallMutation = useMutation({
		mutationKey: ["makeMeetingDelegateCall"],
		mutationFn: () => authenticateAndPostData("/api/meeting-delegate-task/", {
			"voice_id": voice,
			"ai_call_name": aiCallName,
			"user_call_name": userCallName,
			"call_language": language,
			"call_instructions": callInstructions,
			"post_call_agent": agent,
			"agent_instructions": postCallInstructions,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: (response) => {
			let responseData = response.data as MeetingResponse;
			console.log(response);

			// Show meeting URL to user.
			setRoomURL(responseData.meeting_join_url);
			setCallUnderway(true);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			setErrorText(error.data.message);
		},
	});

	// Mutation to create preset.
	const createPresetMutation = useMutation({
		mutationKey: ["createDelegateTaskPreset"],
		mutationFn: (
			presetName: string,
		) => authenticateAndPostData("/api/create-delegate-task-preset/", {
			"preset_name": presetName,
			"call_type": callPlatform,
			"callee_country_code": countryCode,
			"callee_number": phoneNumber,
			"gender": gender,
			"nationality": nationality,
			"voice_id": voice,
			"user_name": userCallName,
			"ai_name": aiCallName,
			"callee_name": calleeName,
			"language": language,
			"call_instructions": callInstructions,
			"post_call_agent": agent,
			"agent_instruction": postCallInstructions,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			showToast(
				"Success!",
				"Preset has been save successfully for Delegate Task Call.",
				"default",
			);
			setOpenSavePreset(false);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive",
			);
		}
	});

	/**
	 * Makes API request with entered details to start the AI conversation.
	 */
	function makeCall() {
		setErrorText("");
		if (callPlatform === "phone_call") {
			// --------- Phone Calls ---------
			if (countryCode &&
				phoneNumber &&
				voice &&
				userCallName &&
				aiCallName &&
				calleeName &&
				language &&
				callInstructions &&
				postCallInstructions) {

				if (userCallName.length > 15) {
					setErrorText("User name cannot exceed 15 characters.");
				} else if (aiCallName.length > 15) {
					setErrorText("AI name cannot exceed 15 characters.");
				} else if (calleeName.length > 15) {
					setErrorText("Callee name cannot exceed 15 characters.");
				} else if (callInstructions.length > 500) {
					setErrorText("Please keep call instruction below 500 characters.");
				} else if (postCallInstructions.length > 500) {
					setErrorText("Please keep post-call instruction below 500 characters.");
				} else {
					makeCallMutation.mutate();
				}

			} else {
				showToast(
					"Missing Values",
					"Please check and fill in all the fields.",
					"destructive",
				);
			}

		} else {
			// --------- Meeting Calls ---------
			if (voice && userCallName && aiCallName && language && callInstructions && postCallInstructions) {
				if (userCallName.length > 15) {
					setErrorText("User name cannot exceed 15 characters.");
				} else if (aiCallName.length > 15) {
					setErrorText("AI name cannot exceed 15 characters.");
				} else if (callInstructions.length > 500) {
					setErrorText("Please keep call instruction below 500 characters.");
				} else if (postCallInstructions.length > 500) {
					setErrorText("Please keep post-call instruction below 500 characters.");
				} else {
					makeMeetingCallMutation.mutate();
				}
			} else {
				showToast(
					"Missing Values",
					"Please check and fill in all the fields.",
					"destructive",
				);
			}
		}

	}

	/**
	 * Adds pre-made instructions and post-call instructions to their respective textarea elements.
	 * @param instr - Call instructions.
	 * @param postCallInstr - Post call instructions.
	 */
	function fillInExampleInstructions(instr: string, postCallInstr: string) {
		setCallInstructions(instr);
		setPostCallInstructions(postCallInstr);
		// setAgent("whatsapp_agent");
	}

	function changeVoice(voiceId: string) {
		if (pageData) {
			let voice: VoiceDetails = pageData.voice_details.filter(value => {
				return value.voice_id === voiceId
			})[0];

			setVoice(voiceId);
			setVoiceSampleURL(voice.sample_audio_url);
		}
	}

	/**
	 * Populates all fields with given preset values.
	 * @param presetID - "id" value of selected preset.
	 */
	function loadPreset(presetID: number) {
		if (pageData) {
			let preset = pageData.saved_presets.filter(preset => {
				return preset.id === presetID;
			})[0];

			// Load the values.
			setCallPlatform(preset.call_type);
			setCountryCode(preset.callee_country_code);
			setPhoneNumber(preset.callee_number);
			setGender(preset.gender);
			setNationality(preset.nationality);
			changeVoice(preset.voice_id);
			setUserCallName(preset.user_name);
			setAiCallName(preset.ai_name);
			setCalleeName(preset.callee_name);
			setLanguage(preset.language);
			setCallInstructions(preset.call_instructions);
			setAgent(preset.post_call_agent);
			setPostCallInstructions(preset.agent_instruction);

			setOpenLoadPreset(false);

		} else {
			console.error("pageData not loaded yet.")
		}
	}

	function savePreset(presetName: string) {
		if (presetName.length === 0) {
			showToast(
				"Invalid Preset Name",
				"Please enter a valid preset name.",
				"destructive"
			);

		} else if (presetName.length > 150) {
			showToast(
				"Invalid Preset Name",
				"Please keep preset name below 150 characters.",
				"destructive"
			);

		} else {
			createPresetMutation.mutate(presetName);
		}
	}

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return !callUnderway ? (
			<div className="flex flex-col w-full items-center">
				<h1 className="text-4xl text-center font-helvetica-neue-bold">
					Let AI call on your behalf & get work done
				</h1>
				<p className="text-xl mt-4 text-center">
					Our AI can call anyone for you, have a conversation, ask questions
					<br/>and send you a summary via WhatsApp.
				</p>
				{/* Load Preset */}
				<div className="w-full flex flex-col items-center mt-4">
					<LoadPresetDialog presets={pageData.saved_presets}
														openLoadPreset={openLoadPreset}
														setOpenLoadPreset={setOpenLoadPreset}
														loadPresetHandler={loadPreset}>
						<Button>
							<ArrowBigDownDash className={"w-5 h-5 mr-2"}/>Load Saved Preset
						</Button>
					</LoadPresetDialog>
				</div>
				{/* Call platform selection */}
				<div
					className="w-full md:w-10/12 xl:w-6/12 flex flex-col items-center mt-10 px-6 py-10 border rounded-2xl transition-all">
					<h1 className="font-helvetica-neue-bold text-2xl text-center">
						How do you want to proceed?
					</h1>
					<Tabs value={callPlatform} onValueChange={setCallPlatform} className="w-full mt-6">
						<TabsList className="grid w-full grid-cols-2">
							<TabsTrigger value="meeting_call">Meeting Call</TabsTrigger>
							<TabsTrigger value="phone_call">Phone Call</TabsTrigger>
						</TabsList>
						<TabsContent value="phone_call" className="mt-6">
							<p>Have the AI call a phone number.</p>
							{/* ------------------ CALLEE NUMBER ------------------ */}
							<div className="w-full flex flex-col mt-2">
								<Label htmlFor="callee-number-input">
									Callee phone number:
								</Label>
								<div className="flex flex-row mt-4 items-center" id="callee-number-input">
									<Plus className="w-6 h-6 mr-2" strokeWidth={3}/>
									<Input type={"text"}
												 className="w-14 mr-2 border"
												 value={countryCode}
												 onChange={e => setCountryCode(e.target.value)}
												 placeholder="91"
												 required/>
									<Input type={"tel"}
												 className="border"
												 value={phoneNumber}
												 onChange={e => setPhoneNumber(e.target.value)}
												 placeholder="9833123456"
												 required/>
								</div>
							</div>
						</TabsContent>
						<TabsContent value="meeting_call" className="mt-6">
							<p>
								Talk with the AI using on a Zoom / Google Meet like video call interface. You will be
								provided with a URL to join the call.
							</p>
						</TabsContent>
					</Tabs>
				</div>
				<div
					className="w-full md:w-10/12 xl:w-6/12 flex flex-col items-center mt-8 p-6 border rounded-2xl transition-all">
					{/* ------------------ GENDER & NATIONALITY SELECTION ------------------ */}
					<div className={"w-full grid grid-cols-2 mt-6 gap-6"}>
						<div className="w-full flex flex-col">
							<Label htmlFor="gender-select">
								Talk with:
							</Label>
							<Select value={gender} onValueChange={setGender} required>
								<SelectTrigger id="gender-select" className="mt-4 border">
									<SelectValue placeholder={"Select Gender"}/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="M">Him</SelectItem>
									<SelectItem value="F">Her</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="w-full flex flex-col">
							<Label htmlFor="nationality-select">
								AI Nationality:
							</Label>
							<Select value={nationality} onValueChange={setNationality} required>
								<SelectTrigger id="nationality-select" className="mt-4 border">
									<SelectValue placeholder={"Select Nationality"}/>
								</SelectTrigger>
								<SelectContent>
									{allNationalities.map(nationality => (
										<SelectItem value={nationality} key={nationality}>{nationality}</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
					</div>
					<section className="w-full">
						{/* ------------------ VOICE LIST ------------------ */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="voice-model-select">
								Select Voice Type:
							</Label>
							<Select value={voice} onValueChange={changeVoice} required>
								<SelectTrigger id="voice-model-select" className="mt-4 border">
									<SelectValue placeholder={"Select Voice"}/>
								</SelectTrigger>
								<SelectContent>
									{filteredVoices.length === 0 && <p className="text-center text-sm py-4">No Voice Available.</p>}
									{filteredVoices.map(v => (
										<SelectItem value={v.voiceId} key={v.voiceId}>
											{v.voiceName}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
							{voiceSampleURL && <audio className="mt-4"
                                        controls
                                        src={voiceSampleURL}></audio>}
						</div>
						{/* ------------------ USER, AI & CALLEE NAME ------------------ */}
						<div className={"w-full flex flex-row mt-6"}>
							{/* AI */}
							<div className="w-full flex flex-col">
								<Label htmlFor="ai-name">
									AI Name:
								</Label>
								<Input type="text"
											 id="ai-name"
											 className="mt-4 border"
											 value={aiCallName}
											 onChange={e => setAiCallName(e.target.value)}
											 placeholder="ex. John"
											 required/>
							</div>
							{/* USER */}
							<div className="w-full flex flex-col ml-4">
								<Label htmlFor="user-name">
									Your Name:
								</Label>
								<Input type="text"
											 id="user-name"
											 className="mt-4 border"
											 value={userCallName}
											 onChange={e => setUserCallName(e.target.value)}
											 placeholder="ex. Jane"
											 required/>
							</div>
							{/* CALLEE */}
							{(callPlatform === "phone_call") && <div className="w-full flex flex-col ml-4">
                  <Label htmlFor="callee-name">
                      Callee Name:
                  </Label>
                  <Input type="text"
                         id="callee-name"
                         className="mt-4 border"
                         value={calleeName}
                         onChange={e => setCalleeName(e.target.value)}
                         placeholder="ex. Dave"
                         required/>
              </div>}
						</div>

						{/* ------------------ CALL LANGUAGE ------------------ */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="language-select">
								Language to use during call:
							</Label>
							<Select value={language} onValueChange={setLanguage} required>
								<SelectTrigger id="language-select" className="mt-4 border">
									<SelectValue placeholder={"Select Language"}/>
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="English">English</SelectItem>
								</SelectContent>
							</Select>
						</div>
					</section>
				</div>
				<div
					className="w-full md:w-10/12 xl:w-6/12 flex flex-col items-center mt-4 p-6 border rounded-2xl transition-all">
					{/* ------------------ CALL INSTRUCTIONS ------------------ */}
					<div className="w-full flex flex-col">
						<Label htmlFor="call-instructions">
							What should the AI do <i>during</i> the call?
						</Label>
						<Textarea id="call-instructions"
											className="mt-4 border"
											value={callInstructions}
											placeholder="Ex. Ask Dave if he is available at 6 PM today for..."
											rows={5}
											onChange={e => setCallInstructions(e.target.value)}
											required/>
					</div>
					<hr className="mt-6 w-full border"/>
					{/* ------------------ AGENT SELECTION ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="agent-selection" className="flex">
							<QuestionMarkTooltip text={agentTooltipText}/>
							&nbsp;&nbsp;Select an Agent:
						</Label>
						<Select onValueChange={setAgent} value={agent} required>
							<SelectTrigger id="agent-selection" className="mt-4 border">
								<SelectValue placeholder={"Select Agent"}/>
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="no_agent">No Agent</SelectItem>
								{pageData.active_integrations.sms &&
                    <SelectItem value="sms_agent">SMS Agent</SelectItem>}
								{pageData.active_integrations.whatsapp &&
                    <SelectItem value="whatsapp_agent">WhatsApp Agent</SelectItem>}
								{pageData.active_integrations.slack &&
                    <SelectItem value="slack_agent">Slack Agent</SelectItem>}
								{pageData.active_integrations.google &&
                    <SelectItem value="google_calendar">Google Calendar</SelectItem>}
							</SelectContent>
						</Select>
						<p className="text-sm mt-2">
							<Info className="w-4 h-4 mr-2 inline"/>
							Please visit <a href={urls["integrations"]} className={"text-primary underline"}>Integrations</a> page
							to set up agents.
						</p>
					</div>
					{/* ------------------ POST CALL AGENT INSTRUCTIONS ------------------ */}
					<div className="w-full flex flex-col mt-6">
						<Label htmlFor="post-call-instructions" className="flex">
							{/*<CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground"/>&nbsp;&nbsp;*/}
							<QuestionMarkTooltip
								text={agentInstructionTooltipText}/>
							&nbsp;&nbsp;Add instructions for the Agent:
						</Label>
						<Textarea id="post-call-instructions"
											className="mt-4 border"
											value={postCallInstructions}
											placeholder="Ex. Send me a summary of the conversation."
											rows={5}
											onChange={e => setPostCallInstructions(e.target.value)}
											disabled={agent === "no_agent" || agent === "google_calendar"}/>
					</div>
					{/* ------------------ EXAMPLE INSTRUCTIONS ------------------ */}
					<div className="w-full mt-4">
						<Label htmlFor="example-instructions">Example:</Label>
						<div className="w-full flex flex-row justify-between flex-wrap mt-2">
							{pageData.instruction_examples.map((example) => (
								<Button size={"sm"}
												className={"m-2"}
												key={example.title}
												onClick={() =>
													fillInExampleInstructions(
														example.instructions,
														example.post_call_instructions
													)}>
									{example.title}
								</Button>
							))}
						</div>
					</div>

				</div>
				<div className="w-full md:w-10/12 xl:w-6/12 flex flex-row justify-center mt-6">
					<SaveAsPresetDialog savePresetHandler={savePreset}
															openSavePreset={openSavePreset}
															setOpenSavePreset={setOpenSavePreset}>
						<Button className="px-8 mx-4" variant={"outline"}>
							<SaveIcon className="w-4 h-4"/>&nbsp;&nbsp;Save as Preset
						</Button>
					</SaveAsPresetDialog>
					<Button className="px-8"
									onClick={makeCall}
									disabled={makeMeetingCallMutation.isPending || makeCallMutation.isPending}>
						{(makeMeetingCallMutation.isPending || makeCallMutation.isPending) ?
							<><PhoneIncoming className="w-4 h-4"/>&nbsp;&nbsp;Starting Up...</> :
							<><PhoneIncoming className="w-4 h-4"/>&nbsp;&nbsp;Start the Call</>}

					</Button>
				</div>
				<p className="text-red-600 mt-4">{errorText}</p>
			</div>) : callPlatform === "phone_call" ?
			<PhoneCallUnderwayPage aiCallName={aiCallName} userCallName={userCallName} calleeName={calleeName}/> :
			<MeetingCallUnderwayPage aiCallName={aiCallName} userCallName={userCallName} roomURL={roomURL}/>

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function MeetingCallUnderwayPage(props: {
	aiCallName: string,
	userCallName: string,
	roomURL: string | undefined,
}) {
	const navigate = useNavigate();

	const [textCopied, setTextCopied] = useState(false);

	return (
		<div className="w-full flex justify-center">
			<div className="w-4/12 flex flex-col items-center mt-12 border rounded-2xl p-6">
				<h1 className="text-4xl font-helvetica-neue-bold">Heads up {props.userCallName}!</h1>
				<p className="mt-2 text-center">
					Your meeting call is room is online. Share the below given Room URL with your target user. You can also
					find this URL later in Call Logs page.
				</p>
				<div className="w-full flex flex-row mt-6 text-center">
					<a
						className={"text-primary underline py-2 px-4 border rounded-2xl grow text-nowrap overflow-hidden overflow-ellipsis"}
						href={props.roomURL} target={"_blank"}
						rel={"noreferrer"}>
						{props.roomURL}
					</a>
					<Button className={"ml-4"} onClick={() => {
						navigator.clipboard.writeText(props.roomURL || "").then(() => {
							setTextCopied(true);
							setTimeout(() => {
								setTextCopied(false);
							}, 2000);
						});
					}}>
						<Clipboard className={"w-4 h-4 mr-2"}/>{textCopied ? "Copied!" : "Copy"}
					</Button>
				</div>
				<p className={"mt-6 text-center"}>
					If there's any issue with the call, please get in touch with us on our <b>live chat support</b>.
				</p>
				<Button className="mt-6" onClick={() => navigate(0)}>
					<ArrowLeft className="h-4 w-4 mr-2"/>Go Back
				</Button>
			</div>
		</div>
	)
}

function PhoneCallUnderwayPage(props: {
	aiCallName: string,
	userCallName: string,
	calleeName: string,
}) {
	const navigate = useNavigate();

	return (
		<div className="w-full flex justify-center">
			<div className="w-4/12 flex flex-col items-center mt-12 border rounded-2xl p-6">
				<h1 className="text-4xl font-helvetica-neue-bold">Heads up {props.userCallName}!</h1>
				<p className="mt-2 text-center">
					Call between <b>{props.aiCallName}</b> (AI) and <b>{props.calleeName}</b> is underway.
					If there's any issue with the call, please get in touch with us on our <b>live chat support</b>.
				</p>
				<Button className="mt-6" onClick={() => navigate(0)}>
					<ArrowLeft className="h-4 w-4 mr-2"/>Go Back
				</Button>
			</div>
		</div>
	)
}

function QuestionMarkTooltip(props: { text: string }) {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground"/>
				</TooltipTrigger>
				<TooltipContent className={"w-72"}>
					<p>{props.text}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}


function LoadPresetDialog(props: {
	children: ReactNode,
	presets: Array<SavedPreset>,
	openLoadPreset: boolean,
	setOpenLoadPreset: (value: boolean) => void,
	loadPresetHandler: (presetID: number) => void,
}) {
	return (
		<Dialog open={props.openLoadPreset} onOpenChange={props.setOpenLoadPreset}>
			<DialogTrigger asChild>
				{props.children}
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Your Delegate Call Presets</DialogTitle>
					<DialogDescription>
						Select a preset from below. All fields will be overwritten.
					</DialogDescription>
				</DialogHeader>
				<div className="w-full flex flex-col">
					<Separator className={"my-2"}/>
					{props.presets.length === 0 && <p>No Presets Available.</p>}
					{props.presets.map(preset => (
						<div className="w-full flex flex-row justify-between items-center my-2" key={preset.id}>
							<p>{preset.preset_name}</p>
							<Button size={"sm"} onClick={() => props.loadPresetHandler(preset.id)}>
								Load
							</Button>
						</div>
					))}
				</div>
			</DialogContent>
		</Dialog>
	)
}

function SaveAsPresetDialog(props: {
	children: ReactNode,
	savePresetHandler: (presetName: string) => void,
	openSavePreset: boolean,
	setOpenSavePreset: (value: boolean) => void,
}) {
	const [presetName, setPresetName] = useState<string>("");

	return (
		<Dialog open={props.openSavePreset} onOpenChange={props.setOpenSavePreset}>
			<DialogTrigger asChild>
				{props.children}
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Save Preset</DialogTitle>
					<DialogDescription>
						Save all current values you have entered as a preset to load them again later in one click.
					</DialogDescription>
				</DialogHeader>
				<div className="w-full flex flex-col items-start justify-start my-4">
					<Label htmlFor="preset-name">
						Preset Name:
					</Label>
					<Input type="text"
								 id="preset-name"
								 className="mt-2 border"
								 value={presetName}
								 onChange={e => setPresetName(e.target.value)}
								 maxLength={150}
								 placeholder="ex. Standup Meeting with John"
								 required/>
				</div>
				<DialogFooter>
					<Button onClick={() => props.savePresetHandler(presetName)}>
						<SaveIcon className="w-4 h-4 mr-2"/>Save Preset
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
