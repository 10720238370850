import {Link, useOutletContext, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {BaseOutletContext} from "@pages/Base";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Button, buttonVariants} from "@components/ui/button";
import {Blocks, Calendar, ChevronRight, Mail, MessageCircle, SaveIcon} from "lucide-react";
import {urls} from "@routes";
import {Checkbox} from "@components/ui/checkbox";

interface PageData {
	status_code: number
	status_text: string

	username: string
	user_email: string;
	extra_usage_seconds: number
	block_all_calls: boolean

	// Integrations
	sms_country_code: string
	sms_number: string
	wa_country_code: string
	wa_number: string
	slackbot_webhook: string
	google_auth_active: boolean
}

// interface SMSIntegrationResponse {
// 	status_code: number
// 	status_text: string
//
// 	action: string
// }

export default function AdminUserDetails() {
	const {userID} = useParams();
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [pageData, setPageData] = useState<PageData>();
	const [extraUsageSeconds, setExtraUsageSeconds] = useState<number>(0);
	const [blockCalls, setBlockCalls] = useState(false);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserDetailsPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-details?id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setExtraUsageSeconds(data.extra_usage_seconds);
			setBlockCalls(data.block_all_calls);
		}
	}, [pageDataQuery.data]);

	// Mutation to save profile changes.
	const saveChangesMutation = useMutation({
		mutationKey: ["saveUserDetailsChanges"],
		mutationFn: () => authenticateAndPostData("/api/admin/user-details/", {
			"user_id": userID,
			"extra_usage_seconds": extraUsageSeconds,
			"block_all_calls": blockCalls,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Show alert and refetch data.
			pageDataQuery.refetch().then();
			showToast(
				"Success!",
				"All changes have been saved successfully.",
				"default"
			);
		},
		onError: (error: ApiRequestFailed) => {
			// Show alert.
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// // Mutation to save SMS integration.
	// const smsIntegrationMutation = useMutation({
	// 	mutationKey: ["adminSmsIntegration"],
	// 	mutationFn: (data: {
	// 		action: string,
	// 		smsCountryCode: string,
	// 		smsNumber: string,
	// 	}) => authenticateAndPostData("/api/admin/integrations/sms/", {
	// 		"user_id": userID || "",
	// 		"action": data.action,
	// 		"sms_country_code": data.smsCountryCode,
	// 		"sms_number": data.smsNumber,
	// 	}),
	// 	gcTime: 0,
	// 	retry: retryFn,
	// 	onSuccess: (response) => {
	// 		let data = response.data.data as SMSIntegrationResponse;
	// 		// Show alert and refetch page data.
	// 		if (data.action === "add") {
	// 			showToast(
	// 				"Success!",
	// 				`SMS integration has been set up for ${pageData!.user_email}`,
	// 				"default",
	// 			);
	//
	// 		} else {
	// 			showToast(
	// 				"Success!",
	// 				`SMS integration removed for ${pageData!.user_email}`,
	// 				"default",
	// 			);
	// 		}
	// 		pageDataQuery.refetch().then();
	// 	},
	// 	onError: (error: ApiRequestFailed) => {
	// 		// Show alert.
	// 		console.error(error);
	// 		showToast(
	// 			"Error",
	// 			error.data.message,
	// 			"destructive",
	// 		);
	// 	}
	// });

	// function addSmsIntegration(smsCountryCode: string, smsNumber: string) {
	// 	if (smsCountryCode && smsNumber) {
	// 		smsIntegrationMutation.mutate({
	// 			action: "add",
	// 			smsCountryCode: smsCountryCode,
	// 			smsNumber: smsNumber,
	// 		});
	// 	} else {
	// 		showToast(
	// 			"Error",
	// 			"Please enter valid values.",
	// 			"destructive",
	// 		);
	// 	}
	// }

	// function removeSmsIntegration() {
	// 	smsIntegrationMutation.mutate({
	// 		action: "remove",
	// 		smsCountryCode: "",
	// 		smsNumber: "",
	// 	});
	// }

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col justify-center items-center">
				<h1 className="font-helvetica-neue-bold text-4xl">
					Manage Account <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
				</h1>
				<hr className="w-full my-8"/>
				<div className="w-full grid grid-cols-3 gap-6">
					{/* --------------------- Editable stuff --------------------- */}
					<div className="w-full h-fit flex flex-col border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Edit Account Details:</h2>
						{/* Extra Usage Seconds */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="extra-usage-seconds">
								Extra Usage Seconds:
							</Label>
							<Input type="number"
										 id="extra-usage-seconds"
										 className="mt-4 border"
										 value={extraUsageSeconds}
										 onChange={e => setExtraUsageSeconds(parseInt(e.target.value))}
										 required/>
						</div>
						{/* Block Calls */}
						<div className="items-top flex space-x-2 mt-6">
							<Checkbox id="block-all-calls" defaultChecked={blockCalls} onClick={() => setBlockCalls(!blockCalls)}/>
							<div className="grid gap-1.5 leading-none">
								<label
									htmlFor="block-all-calls"
									className="text-destructive text-sm font-bold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
								>
									Block All Calls
								</label>
								<p className="text-sm text-muted-foreground">
									Prevents this account from initiating all types of calls except inbound ones.
								</p>
							</div>
						</div>
						<Button className="mt-6" onClick={() => saveChangesMutation.mutate()}>
							<SaveIcon className="w-4 h-4 mr-2"/>Save Changes
						</Button>
					</div>
					{/* --------------------- Links --------------------- */}
					<div className="w-full h-fit grid grid-cols-1 gap-6 border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Call Related Details:</h2>
						<Link to={urls["adminUserCallLogs"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Check Logs<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
						<Link to={urls["adminUserVerifiedNumbers"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Verified Numbers<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
						<Link to={urls["adminUserPurchasedNumbers"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Purchased Numbers<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
					</div>
					{/* --------------------- Integrations --------------------- */}
					<div className="w-full h-fit grid grid-cols-1 gap-6 border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Integrations:</h2>
						<Button variant={"outline"} disabled={true}>
							<Mail className="w-4 h-4 mr-2"/>SMS<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<MessageCircle className="w-4 h-4 mr-2"/>Whatsapp<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<Calendar className="w-4 h-4 mr-2"/>Google Calendar<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<Blocks className="w-4 h-4 mr-2"/>Slack<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
					</div>
				</div>
			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

// function SMSModal(props: {
// 	children: ReactNode,
// 	smsCountryCode: string,
// 	smsNumber: string,
// 	addIntegrationHandler: (countryCode: string, number: string) => void,
// 	removeIntegrationHandler: () => void,
// }) {
// 	const [countryCode, setCountryCode] = useState<string>(props.smsCountryCode);
// 	const [number, setNumber] = useState<string>(props.smsNumber);
//
// 	return (
// 		<AlertDialog>
// 			<AlertDialogTrigger asChild>
// 				{props.children}
// 			</AlertDialogTrigger>
// 			<AlertDialogContent>
// 				<AlertDialogHeader>
// 					<AlertDialogTitle>Text SMS Integration</AlertDialogTitle>
// 					<AlertDialogDescription asChild>
// 						<div className="flex flex-col">
// 							<Label htmlFor="whatsapp-number-input" className="font-bold">
// 								Set Your Mobile Number (with country code):
// 							</Label>
// 							<div id="whatsapp-number-input" className="flex flex-row mt-4">
// 								<span className="text-3xl mr-3 font-bold">+</span>
// 								<Input type="text"
// 									   className="w-16 mr-3 text-lg border"
// 									   value={countryCode}
// 									   onChange={e => setCountryCode(e.target.value)}
// 									   required/>
// 								<Input type="tel"
// 									   className="text-lg border"
// 									   value={number}
// 									   onChange={e => setNumber(e.target.value)}
// 									   required/>
// 							</div>
// 						</div>
// 					</AlertDialogDescription>
// 				</AlertDialogHeader>
// 				<AlertDialogFooter>
// 					<AlertDialogCancel>
// 						Cancel
// 					</AlertDialogCancel>
// 					{(countryCode && number) ?
// 						<AlertDialogAction onClick={props.removeIntegrationHandler} className={"bg-destructive"}>
// 							Remove Integration
// 						</AlertDialogAction> :
// 						<AlertDialogAction onClick={() => props.addIntegrationHandler(countryCode, number)}>
// 							Add
// 						</AlertDialogAction>}
// 				</AlertDialogFooter>
// 			</AlertDialogContent>
// 		</AlertDialog>
// 	)
// }
